import React from 'react'
import { useWindowDimensions } from '@centrito/ui/src'
import { BaseSkeleton } from '@centrito/ui/src/components/BaseSkeleton'

const PADDING_HORIZONTAL = 15

export const CouponsBannerSkeleton: React.FC = () => {
  const { width } = useWindowDimensions()

  const widthToUse = width >= 500 ? 500 : width

  return <BaseSkeleton width={widthToUse - PADDING_HORIZONTAL * 2} height={80} borderRadius={8} />
}
