import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import isNil from 'lodash.isnil'
import PATHS from '@centrito/common/paths'
import { SheetTextButton } from '@centrito/ui/src/components/button/SheetTextButton'
import { TextIconButton } from '@centrito/ui/src/components/button/TextIconButton'
import ArrowRight from '@centrito/ui/src/components/svgs/assets/ArrowRight'

export interface SeeMoreButtonProps {
  isSheetTrigger?: boolean
  categoryNodesPrefix?: string
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  isOpen?: boolean
  text?: string
  color?: string
  children?: React.ReactNode
  onPress?: () => void
}

export const SeeMoreButton: React.FC<SeeMoreButtonProps> = ({
  children,
  categoryNodesPrefix,
  isSheetTrigger = false,
  setIsOpen = (): void => {},
  isOpen = false,
  text = 'Ver todo',
  color = '#FF004F',
  onPress,
}) => {
  const route =
    categoryNodesPrefix !== undefined
      ? categoryNodesPrefix !== ''
        ? {
            pathname: PATHS.Products.ListByCategoryPrefix,
            query: { categoryNodesPrefix },
          }
        : PATHS.Products.ListAll
      : PATHS.Site.Categories

  if (isSheetTrigger && !isNil(children)) {
    return (
      <SheetTextButton
        text={text}
        textColor={color}
        Icon={ArrowRight}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      >
        {children}
      </SheetTextButton>
    )
  }

  return (
    <TextIconButton
      href={route}
      text={text}
      textColor={color}
      Icon={ArrowRight}
      onPress={onPress}
    />
  )
}
