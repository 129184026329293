import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'

const useKeyboardVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      setIsVisible(true)
    })
    const keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', () => {
      setIsVisible(false)
    })

    return () => {
      keyboardDidHideListener.remove()
      keyboardDidShowListener.remove()
    }
  }, [])

  return isVisible
}

export default useKeyboardVisibility
