import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

export const useCategoryNavigation = (): {
  handleCategoryPress: (category: CategoryPublic, subhome?: string) => void
} => {
  const handleCategoryPress = (category: CategoryPublic, subhome?: string): void => {
    posthogClient.captureCustomEvent('shopper_subhome_category_open', {
      categoryName: category.nodes,
      subhome,
    })
  }

  return { handleCategoryPress }
}
