import * as React from 'react'
import type { SVGProps } from 'react'

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} fill="none" {...props}>
    <path
      stroke="#4646F5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.833 10.188h8.334M10.834 6.854l3.333 3.333-3.333 3.333"
    />
  </svg>
)
export default ArrowRight
