import * as React from 'react'
import type { SVGProps } from 'react'

const FullSearch = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#4646F5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m17.5 17.2-3.583-3.44m1.916-4.56c0 3.534-2.984 6.4-6.666 6.4-3.682 0-6.667-2.866-6.667-6.4 0-3.535 2.985-6.4 6.667-6.4s6.666 2.865 6.666 6.4Z"
    />
    <path
      fill="#4646F5"
      d="M9.166 14c2.762 0 5-2.15 5-4.8 0-2.651-2.238-4.8-5-4.8-2.76 0-5 2.149-5 4.8 0 2.65 2.24 4.8 5 4.8Z"
    />
  </svg>
)
export default FullSearch
