import React from 'react'
import PATHS from '@centrito/common/paths'
import type { CategoryPublic } from '@centrito/api/nest/platform/database/domain'
import { DisplayType } from '@centrito/app/features/shopper/home/components/utils/constants'
import { useCategoryNavigation } from '@centrito/app/hooks/useCategoryNavigation'
import { ScrollView, XStack, YStack } from '@centrito/ui/src'
import { LargeSquareFeedButton, SmallSquareFeedButton } from '@centrito/ui/src/components/button'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

interface CategoryScrollViewProps {
  displayType: DisplayType
  bottomElements?: CategoryPublic[]
  topElements?: CategoryPublic[]
  categoriesToUse?: CategoryPublic[]
  subhome?: string
}

export const CategoryScrollView: React.FC<CategoryScrollViewProps> = ({
  displayType,
  bottomElements,
  topElements,
  categoriesToUse,
  subhome,
}) => {
  const { handleCategoryPress } = useCategoryNavigation()

  const getHref = (
    category: CategoryPublic,
  ): { pathname?: string; query?: { categoryNodesPrefix: string } } => {
    return {
      pathname: PATHS.Products.ListByCategoryPrefix,
      query: {
        categoryNodesPrefix: category.nodes,
      },
    }
  }

  if (displayType === DisplayType.CIRCLE && bottomElements && topElements && categoriesToUse)
    return (
      <ScrollView
        key={'key ' + categoriesToUse[0].nodes}
        paddingBottom={20}
        paddingHorizontal={12}
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <YStack paddingRight={10} gap={'$2'}>
          <XStack alignContent="flex-start" gap={categoriesToUse.length % 2 == 0 ? 0 : 8}>
            {topElements.map((category) => (
              <CircleFeedButton
                href={getHref(category)}
                key={`category-browser--${category.nodes}`}
                imageSrc={category.assets.tab ?? ''}
                imageAlt={category.nodes}
                label={category.nodesNamed.split('-').pop() ?? ''}
                onPress={(): void => handleCategoryPress(category, subhome)}
              />
            ))}
          </XStack>
          <XStack>
            {bottomElements.map((category) => (
              <CircleFeedButton
                href={getHref(category)}
                key={`category-browser--${category.nodes}`}
                imageSrc={category.assets.tab ?? ''}
                imageAlt={category.nodes}
                label={category.nodesNamed.split('-').pop() ?? ''}
                onPress={(): void => handleCategoryPress(category, subhome)}
              />
            ))}
          </XStack>
        </YStack>
      </ScrollView>
    )
  else if (displayType === DisplayType.SMALL_RECTANGLE && categoriesToUse)
    return (
      <ScrollView
        key={'key ' + categoriesToUse[0].nodes}
        paddingBottom={20}
        paddingHorizontal={12}
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <XStack paddingRight={10}>
          {categoriesToUse.map((category) => (
            <SmallSquareFeedButton
              href={getHref(category)}
              key={`category-browser--${category.nodes}`}
              imageSrc={category.assets.tab ?? ''}
              imageAlt={category.nodes}
              label={category.nodesNamed.split('-').pop() ?? ''}
              onPress={(): void => handleCategoryPress(category, subhome)}
            />
          ))}
        </XStack>
      </ScrollView>
    )
  else if (displayType === DisplayType.LARGE_RECTANGLE && categoriesToUse)
    return (
      <ScrollView
        key={'key ' + categoriesToUse[0].nodes}
        paddingBottom={20}
        paddingHorizontal={12}
        showsHorizontalScrollIndicator={false}
        horizontal
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
      >
        <XStack paddingRight={10}>
          {categoriesToUse.map((category) => (
            <LargeSquareFeedButton
              href={getHref(category)}
              key={`category-browser--${category.nodes}`}
              imageSrc={category.assets.tab ?? ''}
              imageAlt={category.nodes}
              label={category.nodesNamed.split('-').pop() ?? ''}
              onPress={(): void => handleCategoryPress(category, subhome)}
            />
          ))}
        </XStack>
      </ScrollView>
    )
  else return null
}
