import React from 'react'
import type { IconProps } from '@tamagui/helpers-icon'
import { ChevronLeft } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { useRouter } from 'solito/router'
import { Circle, XStack, styled } from 'tamagui'

export interface BackButtonSmallProps {
  onPress?: () => void
  copy?: string
  Icon?: React.NamedExoticComponent<IconProps>
  color?: string
}

const Container = styled(XStack, {
  alignItems: 'center',
  height: 57,
  paddingVertical: 11,
  paddingHorizontal: 16,
  gap: 10,
})

export const BackButtonSmall: React.FC<BackButtonSmallProps> = ({
  onPress = undefined,
  Icon = ChevronLeft,
  color = '#FF004F',
}) => {
  const router = useRouter()
  const isNavStackEmpty = typeof window !== 'undefined' && window.history.length <= 1
  if (isNil(onPress)) onPress = isNavStackEmpty ? (): void => router.push('/') : router.back
  return (
    <Container onPress={onPress} padding={0} justifyContent="center" paddingTop={6}>
      <Circle borderWidth={2} borderColor={color} size={24} cursor="pointer" alignContent="center">
        <Icon color={color} />
      </Circle>
    </Container>
  )
}
