import React from 'react'
import isNil from 'lodash.isnil'
import FeedProductsGroup from '@centrito/app/components/Feed/ProductGroup'
import useDeepLinkFilters from '@centrito/app/utils/hooks/useDeepLinkFilters'
import useInfiniteScrollSubhome from '@centrito/app/utils/hooks/useInfiniteScrollSubhome'
import { FeedType } from '@centrito/app/utils/services/analytics/posthog/utils/FeedType'
import FeedViewEmpty from '@centrito/ui/src/components/FeedViewEmpty'
import FeedProductsGroupSkeleton from '@centrito/ui/src/components/skeletons/ProductGroupSkeleton'
import SubhomeSkeleton from '@centrito/ui/src/components/skeletons/SubhomeSkeleton'

export interface SubhomeFeedProps {
  isShowingFilters?: boolean
  header?: JSX.Element
  subhome?: string
  isMainHome?: boolean
}

const SubhomeFeed: React.FC<SubhomeFeedProps> = ({
  isShowingFilters = true,
  header = undefined,
  subhome = undefined,
  isMainHome = false,
}): JSX.Element => {
  const { products, hasNextPage, loadMoreProducts, isLoading, productsCount } =
    useInfiniteScrollSubhome(subhome)
  useDeepLinkFilters()

  if (isLoading) {
    if (isNil(subhome) || isMainHome)
      return <FeedProductsGroupSkeleton isShowingFilters={isShowingFilters} />
    else return <SubhomeSkeleton />
  }

  if (isNil(products) || products.length === 0) {
    if (!isLoading && productsCount === 0) {
      return <FeedViewEmpty />
    }
    if (isNil(subhome) || isMainHome) {
      return <FeedProductsGroupSkeleton isShowingFilters={isShowingFilters} />
    }
    return <SubhomeSkeleton />
  }

  return (
    <FeedProductsGroup
      products={products}
      hasMoreProducts={hasNextPage || false}
      loadMoreProducts={loadMoreProducts}
      feedType={isMainHome ? FeedType.HOME : FeedType.SUBHOME}
      header={header}
      isShowingFilters={false}
      productsCount={productsCount}
    />
  )
}

export default SubhomeFeed
