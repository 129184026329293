import * as React from 'react'

const CartSvg = ({ stroke = '#AAA', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.167 16.667a.833.833 0 1 1 1.666 0 .833.833 0 0 1-1.666 0ZM5.833 16.667a.833.833 0 1 1 1.667 0 .833.833 0 0 1-1.667 0ZM2.5 2.5h.64c.402 0 .746.287.82.682l1.873 10.151M16.452 5H4.583l1.25 8.333h9.08a.833.833 0 0 0 .813-.646l1.538-6.666A.833.833 0 0 0 16.452 5Z"
    />
  </svg>
)
export default CartSvg
