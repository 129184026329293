import React from 'react'
import { MainHome } from '@centrito/app/features/shopper/home/components/MainHome'
import { SubhomeScreenProvider } from '@centrito/app/features/shopper/home/context'

export const HomeScreen: React.FC = () => {
  return (
    <SubhomeScreenProvider>
      <MainHome />
    </SubhomeScreenProvider>
  )
}
