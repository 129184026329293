import * as React from 'react'

const SvgComponent = ({ stroke = '#AAA', strokeWidth = 2, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none" {...props}>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      d="M14.382 7.084 8.549 1.98a.833.833 0 0 0-1.098 0L1.618 7.084a.833.833 0 0 0-.285.627v7.122c0 .46.373.833.834.833H5.5c.46 0 .833-.373.833-.833v-3.334c0-.46.373-.833.834-.833h1.666c.46 0 .834.373.834.833v3.334c0 .46.373.833.833.833h3.333c.46 0 .834-.373.834-.833V7.71a.833.833 0 0 0-.285-.627Z"
    />
  </svg>
)
export default SvgComponent
