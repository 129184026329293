import * as React from 'react'

const SubHomeSquare = ({ stroke }: { stroke: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.937 2.813h-2.5a.625.625 0 0 0-.625.624v2.5c0 .346.28.625.625.625h2.5c.345 0 .625-.28.625-.625v-2.5a.625.625 0 0 0-.625-.624ZM5.938 8.438h-2.5a.625.625 0 0 0-.626.624v2.5c0 .346.28.626.626.626h2.5c.345 0 .624-.28.624-.626v-2.5a.625.625 0 0 0-.625-.624ZM11.562 2.813h-2.5a.625.625 0 0 0-.625.624v2.5c0 .346.28.626.625.626h2.5c.345 0 .625-.28.625-.625v-2.5a.625.625 0 0 0-.625-.626ZM11.563 8.438h-2.5a.625.625 0 0 0-.626.624v2.5c0 .346.28.626.626.626h2.5c.345 0 .624-.28.624-.626v-2.5a.625.625 0 0 0-.624-.624Z"
    />
  </svg>
)
export default SubHomeSquare
