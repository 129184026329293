import React from 'react'
import { XStack } from '@centrito/ui/src'

interface HomeValueProposalCardProps {
  Icon: React.ReactNode
  Content: React.ReactNode
}

export const HomeValueProposalCard: React.FC<HomeValueProposalCardProps> = ({ Icon, Content }) => {
  return (
    <XStack
      alignItems="center"
      backgroundColor="$pureWhite"
      paddingVertical={7}
      paddingHorizontal={15}
      width="50%"
      gap={10}
      minHeight={50}
    >
      {Icon}
      {Content}
    </XStack>
  )
}
