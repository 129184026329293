import * as React from 'react'

const UserSvg = ({ stroke = '#AAA', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3.333 17.5c0-2.3 2.985-4.166 6.667-4.166s6.667 1.866 6.667 4.167M10 10.833A4.167 4.167 0 1 0 10 2.5a4.167 4.167 0 0 0 0 8.333Z"
    />
  </svg>
)
export default UserSvg
