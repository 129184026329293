import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from '@centrito/app/store'
import { selectScrollingToTop } from '@centrito/app/store/selectors'
import { actions } from '@centrito/app/store/slices/userData'
import ReactNativeNextJsScrollRestore from '@centrito/app/utils/scrollRestore'
import { ScrollView, type YStackProps } from '@centrito/ui/src'
import { MOBILE_WIDTH_STYLES } from '@centrito/ui/src/constants'

interface MainHomeLayoutProps {
  yStackProps?: Omit<YStackProps, 'children'>
  isHome?: boolean
  children: React.ReactNode
}
const { useScroller } = ReactNativeNextJsScrollRestore

const MainHomeLayout: React.FC<MainHomeLayoutProps> = ({ children, isHome = false }) => {
  const scrollViewRef = useRef(null)

  const { onScroll } = useScroller(scrollViewRef, {
    // optional, in ms
    scrollDelay: 0,
  })
  const dispatch = useDispatch()

  const isScrollingToTop = useSelector(selectScrollingToTop)

  useEffect(() => {
    if (isScrollingToTop && scrollViewRef.current) {
      ;(scrollViewRef.current as ScrollView).scrollTo({
        x: 0,
        y: 0,
        animated: false,
      })
      dispatch(actions.setScrollingToTop({ isScrollingToTop: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollingToTop])

  return (
    <ScrollView
      ref={scrollViewRef}
      onScroll={onScroll}
      stickyHeaderIndices={isHome ? [4] : undefined}
      {...MOBILE_WIDTH_STYLES}
      contentContainerStyle={{ flexGrow: 1 }}
      paddingBottom={50}
      scrollEventThrottle={16}
    >
      {children}
    </ScrollView>
  )
}

export default MainHomeLayout
