import React from 'react'
import MainHomeLayout from '@centrito/app/layouts/MainHomeLayout'
import ShopperTabBar from '@centrito/app/layouts/ShopperTabBarLayout/TabBar'

interface MainHomeTabBarLayoutProps {
  isFeedView?: boolean
  isHome?: boolean
  children: React.ReactNode
}

const MainHomeTabBarLayout: React.FC<MainHomeTabBarLayoutProps> = ({
  children,
  isFeedView = true,
  isHome = false,
}) => {
  if (isFeedView) {
    return (
      <React.Fragment>
        {children}
        <ShopperTabBar />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <MainHomeLayout
        yStackProps={{ paddingBottom: 42, backgroundColor: '$pureWhite' }}
        isHome={isHome}
      >
        {children}
      </MainHomeLayout>
      <ShopperTabBar />
    </React.Fragment>
  )
}

export default MainHomeTabBarLayout
