export const CATEGORY_TAB_FILTER = [
  '',
  'BELLEZA',
  'MUJER',
  'COCINA',
  'MASCOTAS',
  'ACCESORIOS_DE_BELLEZA',
  'NIÑO',
  'PAPELERÍA',
  'NIÑA',
  'HOMBRE',
  'HOGAR',
  'PIÑATERIA',
  'JUGUETES',
  'JOYERÍA_Y_ACCESORIOS',
  'CALZADO',
  'ASEO',
  'ACCESORIOS_ELECTRÓNICOS',
  'SUPLEMENTOS_DEPORTIVOS',
  'MATERNIDAD_Y_BEBÉS',
  'ACCESORIOS_DEPORTIVOS',
  'ADULTOS',
]

export const PRODUCT_LIST_TAB_FILTER = ['', 'Recomendados', 'MasVendidos', 'Ofertas', 'Novedades']
