import { SubhomeKey, type SubhomeScreenContextType, TabBarOptions } from './types'

export const subhomeScreenContext: SubhomeScreenContextType = {
  currentScreen: SubhomeKey.MAIN,
  changeScreen: () => null,
  categories: {},
  currentTabBarOption: TabBarOptions.EXPLORE,
  updateTabBarOption: () => null,
  currentPages: {},
  updateCurrentPages: () => null,
  isTabBarSticky: false,
  updateIsTabBarSticky: () => null,
}
