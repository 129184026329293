import type { GetStaticProps, NextApiRequest, NextApiResponse } from 'next'
import { createServerSideHelpers } from '@trpc/react-query/server'
import superjson from 'superjson'
import { appRouter, createContext } from '@centrito/api'
import { REVALIDATE_HOME_PAGE_INTERVAL } from '@centrito/api/config'
import HomeScreen from '@centrito/app/features/shopper/home/screen'

export const getStaticProps: GetStaticProps = async () => {
  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext({
      req: undefined as any as NextApiRequest,
      res: undefined as any as NextApiResponse,
    }),
    transformer: superjson,
  })

  await Promise.all([
    ssg.catalog.category.findMany.prefetch(),
    ssg.catalog.homeBanner.findMany.prefetch(),
  ])
  return {
    props: {
      SEOProperties: {
        urlPath: '/',
      },
      trpcState: ssg.dehydrate(),
    },
    revalidate: REVALIDATE_HOME_PAGE_INTERVAL,
  }
}

export default HomeScreen
