import React, { memo } from 'react'
import { BannerPlatform } from '@prisma/client/react-native'
import { LinearGradient } from '@tamagui/linear-gradient'
import { BrandScrollView } from '@centrito/app/features/shopper/home/components/BrandScrollView'
import { CategoryDisplay } from '@centrito/app/features/shopper/home/components/CategoryDisplay'
import { CouponBanner } from '@centrito/app/features/shopper/home/components/CouponBanner'
import { HomeBannerSlider } from '@centrito/app/features/shopper/home/components/HomeBannerSlider'
import { HomeValueProposalCards } from '@centrito/app/features/shopper/home/components/HomeValueProposalCards'
import { ProductListTabBar } from '@centrito/app/features/shopper/home/components/ProductListTabBar'
import SubhomeFeed from '@centrito/app/features/shopper/home/components/SubhomeFeed'
import { SubhomeKey } from '@centrito/app/features/shopper/home/context/types'
import MainHomeTabBarLayout from '@centrito/app/layouts/MainHomeTabBarLayout'
import { Stack } from '@centrito/ui/src'
import { SubtitleText3 } from '@centrito/ui/src/components/text/new/subtitles/SubtitleText3'

interface SubhomeProps {
  subhome: string
}

const MemoizedSubhome = memo(SubhomeFeed)

const Subhome: React.FC<SubhomeProps> = ({ subhome }) => {
  const isHome = subhome === '/'

  if (isHome) {
    return (
      <MainHomeTabBarLayout isFeedView={false} isHome>
        <HomeBannerSlider platform={BannerPlatform.WEB} subhome={subhome} />
        <HomeValueProposalCards />
        <CouponBanner />
        <CategoryDisplay subhome={subhome} />
        <ProductListTabBar />
        <Stack>
          <MemoizedSubhome isMainHome isShowingFilters={false} />
        </Stack>
      </MainHomeTabBarLayout>
    )
  }

  return (
    <MainHomeTabBarLayout isFeedView={false}>
      <HomeBannerSlider platform={BannerPlatform.WEB} subhome={subhome} />
      <CategoryDisplay subhome={subhome} />
      {subhome === SubhomeKey.BELLEZA && <BrandScrollView />}
      <LinearGradient
        borderRadius={20}
        width={'100%'}
        colors={['$samsamBlueTranslucent10', '$Gray4']}
        end={{ x: 1, y: 0.05 }}
      >
        <Stack width={'100%'} alignItems="center">
          <SubtitleText3 paddingTop={10}>Para ti</SubtitleText3>
        </Stack>
        <SubhomeFeed isShowingFilters={false} isMainHome={isHome} />
      </LinearGradient>
    </MainHomeTabBarLayout>
  )
}

export default React.memo(Subhome)
